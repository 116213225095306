import React from 'react';
import {
  Building2,
  Network,
  Shield,
  GitBranch,
  Database,
  LineChart,
  Code2,
  MessageSquare,
  Settings,
  RefreshCw
} from 'lucide-react';



const SystemArchitecture = () => (
  <svg className="w-full" viewBox="0 0 1000 700">
    <defs>
      <linearGradient id="architectureGradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="#3B82F6" stopOpacity="0.2"/>
        <stop offset="100%" stopColor="#06B6D4" stopOpacity="0.2"/>
      </linearGradient>
      <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
        <feOffset dx="2" dy="2"/>
        <feComponentTransfer>
          <feFuncA type="linear" slope="0.3"/>
        </feComponentTransfer>
        <feMerge>
          <feMergeNode/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>

    {/* Systèmes Externes */}
    <g transform="translate(0, 50)">
      {[
        { x: 150, label: "SWIFT", subtext: "MT/MX Messages" },
        { x: 350, label: "RTGS", subtext: "High-Value" },
        { x: 550, label: "ACH", subtext: "Bulk/Batch" },
        { x: 750, label: "APIs", subtext: "Rest/Soap" }
      ].map((system, i) => (
        <g key={i} filter="url(#shadow)">
          <rect
            x={system.x - 70}
            y={20}
            width="140"
            height="80"
            fill="#0F172A"
            stroke="#3B82F6"
            strokeWidth="2"
            rx="8"
          />
          <text
            x={system.x}
            y={55}
            fill="white"
            fontSize="16"
            fontWeight="bold"
            textAnchor="middle"
          >
            {system.label}
          </text>
          <text
            x={system.x}
            y={80}
            fill="white"
            fontSize="12"
            textAnchor="middle"
            opacity="0.7"
          >
            {system.subtext}
          </text>
        </g>
      ))}
    </g>

    {/* Core System */}
    <g transform="translate(100, 200)">
      <rect
        width="800"
        height="400"
        fill="url(#architectureGradient)"
        stroke="#3B82F6"
        strokeWidth="2"
        rx="12"
        filter="url(#shadow)"
      />

      {/* Core Components */}
      <g transform="translate(50, 40)">
        {[
          ["Message Processor", "Workflow Engine", "Routing Service"],
          ["Format Transformer", "Validation Engine", "Treasury Service"],
          ["Compliance Service", "Fee Calculator", "Reporting Service"]
        ].map((row, i) => (
          row.map((service, j) => (
            <g key={`${i}-${j}`} transform={`translate(${j * 250}, ${i * 100})`}>
              <rect
                width="200"
                height="60"
                fill="#0F766E"
                stroke="#3B82F6"
                strokeWidth="1"
                rx="8"
              />
              <text
                x="100"
                y="35"
                fill="white"
                fontSize="14"
                textAnchor="middle"
              >
                {service}
              </text>
            </g>
          ))
        ))}
      </g>

      {/* Infrastructure Layer */}
      <g transform="translate(50, 340)">
        <rect
          width="700"
          height="40"
          fill="#1E4976"
          stroke="#3B82F6"
          strokeWidth="1"
          rx="6"
        />
        <text
          x="350"
          y="25"
          fill="white"
          fontSize="14"
          textAnchor="middle"
        >
          Event Store | Message Queue | Distributed Cache | Monitoring
        </text>
      </g>
    </g>

    {/* Connections */}
    <g>
      {[150, 350, 550, 750].map((x, i) => (
        <path
          key={i}
          d={`M${x} 150 L${x} 200`}
          stroke="#3B82F6"
          strokeWidth="2"
          strokeDasharray="4"
          markerEnd="url(#arrowhead)"
        />
      ))}
    </g>

    {/* Arrow Marker */}
    <defs>
      <marker
        id="arrowhead"
        markerWidth="10"
        markerHeight="7"
        refX="9"
        refY="3.5"
        orient="auto"
      >
        <polygon
          points="0 0, 10 3.5, 0 7"
          fill="#3B82F6"
        />
      </marker>
    </defs>
  </svg>
);


const ProcessDiagram = () => (
  <svg className="w-full" viewBox="0 0 1200 800">
    <defs>
      <linearGradient id="architectureGradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="#3B82F6" stopOpacity="0.1"/>
        <stop offset="100%" stopColor="#06B6D4" stopOpacity="0.1"/>
      </linearGradient>
    </defs>

    {/* Conteneur principal */}
    <rect x="100" y="50" width="1000" height="700" fill="#1E293B" rx="20"/>

    {/* API Gateway & Sécurité */}
    <rect x="150" y="80" width="900" height="60" fill="#0F766E" rx="8"/>
    <text x="600" y="115" fill="white" fontSize="16" textAnchor="middle">
      API Gateway / Authentication / Authorization / Encryption
    </text>

    {/* Core Domain */}
    <rect x="150" y="160" width="900" height="280" fill="url(#architectureGradient)" rx="8"/>
    <text x="600" y="190" fill="white" fontSize="20" fontWeight="bold" textAnchor="middle">
      Core Domain Services
    </text>

    {/* Services Métier */}
    <g transform="translate(170, 210)">
      {[
        ["Message Processing", "Payment Processing", "Workflow Engine", "Event Management"],
        ["Channel Management", "Format Transformation", "Routing Service", "Validation Service"],
        ["Compliance Service", "Treasury Service", "Reporting Service", "Fee Management"]
      ].map((row, i) => row.map((service, j) => (
        <g key={`${i}-${j}`} transform={`translate(${j * 220}, ${i * 70})`}>
          <rect width="200" height="50" fill="#0F766E" rx="8"/>
          <text x="100" y="30" fill="white" fontSize="12" textAnchor="middle">
            {service}
          </text>
        </g>
      )))}
    </g>

    {/* Canaux */}
    <rect x="150" y="460" width="900" height="120" fill="#1E4976" rx="8"/>
    <text x="600" y="485" fill="white" fontSize="18" textAnchor="middle">
      Channel Integration Layer
    </text>

    <g transform="translate(170, 500)">
      {[
        ["SWIFT Channel", "Swift GPI", "MT/MX Support", "Correspondent Management"],
        ["RTGS Channel", "Queue Management", "Settlement", "Priority Handling"],
        ["ACH Channel", "Batch Processing", "Session Management", "Clearing Support"],
      ].map((row, i) => (
        <g key={i} transform={`translate(${i * 290}, 0)`}>
          <rect width="270" height="60" fill="#0F766E" rx="4"/>
          <text x="135" y="25" fill="white" fontSize="12" textAnchor="middle">
            {row[0]}
          </text>
          <text x="135" y="45" fill="white" fontSize="10" textAnchor="middle">
            {`${row[1]} | ${row[2]} | ${row[3]}`}
          </text>
        </g>
      ))}
    </g>

    {/* Infrastructure Transverse */}
    <rect x="150" y="600" width="900" height="100" fill="#1E4976" rx="8"/>
    <text x="600" y="625" fill="white" fontSize="16" textAnchor="middle">
      Infrastructure Transverse
    </text>

    <g transform="translate(170, 640)">
      {[
        "Event Store",
        "Distributed Cache",
        "Message Queue",
        "Monitoring/Metrics",
        "Audit Trail"
      ].map((service, i) => (
        <g key={i} transform={`translate(${i * 175}, 0)`}>
          <rect width="155" height="40" fill="#0F766E" rx="4"/>
          <text x="77.5" y="25" fill="white" fontSize="12" textAnchor="middle">
            {service}
          </text>
        </g>
      ))}
    </g>

    {/* Connecteurs Externes */}
    <g transform="translate(50, 200)">
      <rect y="0" width="80" height="400" fill="#475569" rx="4"/>
      <text x="40" y="30" fill="white" fontSize="12" textAnchor="middle">External</text>
      <text x="40" y="50" fill="white" fontSize="12" textAnchor="middle">Networks</text>
      {["SWIFT", "RTGS", "ACH", "APIs"].map((network, i) => (
        <text key={i} x="40" y={100 + i * 80} fill="white" fontSize="12" textAnchor="middle">
          {network}
        </text>
      ))}
    </g>
  </svg>
);

export default function MXPAYLanding() {
  return (
    <div className="min-h-screen bg-[#050A1C] text-white">
      {/* Hero Section */}
      <div className="relative pt-32 pb-20">
        <div className="container mx-auto px-4">
          <div className="max-w-5xl mx-auto text-center mb-16">
            <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-blue-500/10 border border-blue-500/20 mb-8">
              <Building2 className="text-blue-400"/>
              <span>Système Complet de Gestion des Paiements</span>
            </div>

            <h1 className="text-6xl font-bold mb-8">
              MXPAY
              <span className="block text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-cyan-400">
                Redéfinissez votre Infrastructure de Paiements
              </span>
            </h1>

            <p className="text-xl text-white/70 mb-12 leading-relaxed">
              Une plateforme unifiée qui transforme la gestion de vos paiements,
              <br />de l'initiation à la compensation, avec un contrôle total et une flexibilité inégalée.
            </p>

            <div className="flex gap-6 justify-center">
              <button className="px-8 py-4 rounded-lg bg-gradient-to-r from-blue-500 to-cyan-500 hover:from-blue-600 hover:to-cyan-600 text-white font-medium transition-all">
                Demander une démo
              </button>
             
            </div>
          </div>

          {/* Process Flow Diagram */}
          <div className="max-w-6xl mx-auto mt-24">
            <ProcessDiagram />
          </div>
        </div>
      </div>

      {/* Key Features */}
      <section className="py-32 bg-gradient-to-b from-transparent to-black/30">
        <div className="container mx-auto px-4">
          <div className="max-w-5xl mx-auto">
            <div className="text-center mb-20">
              <h2 className="text-4xl font-bold mb-6">
                Une Solution
                <span className="text-blue-400"> Complète</span>
              </h2>
              <p className="text-xl text-white/70">
                MXPAY couvre l'ensemble de vos besoins en matière de gestion des paiements
              </p>
            </div>

            <div className="grid grid-cols-2 gap-24 items-center">
              <div>
                <SystemArchitecture />
              </div>
              <div>
                <h3 className="text-2xl font-bold mb-8">Architecture Modulaire</h3>
                <div className="space-y-6">
                  <div className="flex gap-4 items-start">
                    <div className="p-2 rounded-lg bg-blue-500/10">
                      <Network className="text-blue-400 w-6 h-6" />
                    </div>
                    <div>
                      <h4 className="font-semibold mb-2">Multi-Canal</h4>
                      <p className="text-white/70">Intégration native avec SWIFT, RTGS et ACH avec routage intelligent</p>
                    </div>
                  </div>
                  <div className="flex gap-4 items-start">
                    <div className="p-2 rounded-lg bg-blue-500/10">
                      <Settings className="text-blue-400 w-6 h-6" />
                    </div>
                    <div>
                      <h4 className="font-semibold mb-2">Workflow Flexible</h4>
                      <p className="text-white/70">Moteur de workflow permettant l'adaptation aux processus spécifiques</p>
                    </div>
                  </div>
                  <div className="flex gap-4 items-start">
                    <div className="p-2 rounded-lg bg-blue-500/10">
                      <RefreshCw className="text-blue-400 w-6 h-6" />
                    </div>
                    <div>
                      <h4 className="font-semibold mb-2">Transformation Native</h4>
                      <p className="text-white/70">Support complet des standards MT et ISO 20022</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Use Cases */}
      <section className="py-32 bg-gradient-to-b from-black/30 to-transparent">
  <div className="container mx-auto px-4">
    <div className="max-w-6xl mx-auto">
      <div className="text-center mb-20">
        <h2 className="text-4xl font-bold mb-6">
          Des Solutions pour Tous vos
          <span className="text-blue-400"> Besoins</span>
        </h2>
        <p className="text-xl text-white/70 mb-12">
          MXPAY s'adapte à tous les cas d'usage de l'industrie financière, des paiements instantanés aux traitements de masse, en passant par la gestion des liquidités internationales
        </p>
      </div>

      <div className="grid grid-cols-2 gap-8 mb-16">
        <div className="bg-white/5 rounded-xl border border-white/10 p-8 hover:border-blue-500/50 transition-all">
          <div className="flex items-center gap-4 mb-4">
            <div className="p-2 rounded-lg bg-blue-500/10">
              <Network className="text-blue-400 w-8 h-8" />
            </div>
            <h3 className="text-2xl font-bold">Virements Internationaux</h3>
          </div>
          <p className="text-white/70 mb-6">
            Une solution complète pour vos opérations transfrontalières, intégrant les dernières innovations SWIFT GPI et ISO 20022. Optimisez vos flux internationaux avec une visibilité en temps réel et une gestion automatisée des correspondants.
          </p>
          <ul className="space-y-3">
            <li className="flex items-center gap-3">
              <div className="w-1.5 h-1.5 rounded-full bg-blue-400" />
              <span className="text-white/80">Support natif MT & MX avec transformation bidirectionnelle</span>
            </li>
            <li className="flex items-center gap-3">
              <div className="w-1.5 h-1.5 rounded-full bg-blue-400" />
              <span className="text-white/80">Tracking GPI temps réel et reporting détaillé</span>
            </li>
            <li className="flex items-center gap-3">
              <div className="w-1.5 h-1.5 rounded-full bg-blue-400" />
              <span className="text-white/80">Gestion intelligente du réseau de correspondants</span>
            </li>
            <li className="flex items-center gap-3">
              <div className="w-1.5 h-1.5 rounded-full bg-blue-400" />
              <span className="text-white/80">Optimisation automatique des coûts et du routage</span>
            </li>
          </ul>
        </div>

        <div className="bg-white/5 rounded-xl border border-white/10 p-8 hover:border-blue-500/50 transition-all">
          <div className="flex items-center gap-4 mb-4">
            <div className="p-2 rounded-lg bg-blue-500/10">
              <Building2 className="text-blue-400 w-8 h-8" />
            </div>
            <h3 className="text-2xl font-bold">Paiements Domestiques</h3>
          </div>
          <p className="text-white/70 mb-6">
            Une plateforme unifiée pour tous vos paiements locaux, combinant la puissance du RTGS pour les paiements urgents et l'efficacité du système ACH pour les volumes importants. 
          </p>
          <ul className="space-y-3">
            <li className="flex items-center gap-3">
              <div className="w-1.5 h-1.5 rounded-full bg-blue-400" />
              <span className="text-white/80">Support intégré RTGS et ACH avec routage intelligent</span>
            </li>
            <li className="flex items-center gap-3">
              <div className="w-1.5 h-1.5 rounded-full bg-blue-400" />
              <span className="text-white/80">Gestion avancée des sessions et de la compensation</span>
            </li>
            <li className="flex items-center gap-3">
              <div className="w-1.5 h-1.5 rounded-full bg-blue-400" />
              <span className="text-white/80">Traitement optimisé des opérations en masse</span>
            </li>
            <li className="flex items-center gap-3">
              <div className="w-1.5 h-1.5 rounded-full bg-blue-400" />
              <span className="text-white/80">Monitoring temps réel et tableaux de bord personnalisés</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-8">
        <div className="bg-white/5 rounded-xl border border-white/10 p-6 hover:border-blue-500/50 transition-all">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 rounded-lg bg-blue-500/10">
              <LineChart className="text-blue-400 w-6 h-6" />
            </div>
            <h4 className="text-xl font-bold">Trésorerie</h4>
          </div>
          <p className="text-white/70">
            Gestion complète de la trésorerie avec suivi des positions en temps réel, gestion des limites et reporting réglementaire automatisé.
          </p>
        </div>

        <div className="bg-white/5 rounded-xl border border-white/10 p-6 hover:border-blue-500/50 transition-all">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 rounded-lg bg-blue-500/10">
              <Shield className="text-blue-400 w-6 h-6" />
            </div>
            <h4 className="text-xl font-bold">Conformité</h4>
          </div>
          <p className="text-white/70">
            Contrôles de conformité intégrés avec screening des sanctions, détection de la fraude et piste d'audit complète.
          </p>
        </div>

        <div className="bg-white/5 rounded-xl border border-white/10 p-6 hover:border-blue-500/50 transition-all">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 rounded-lg bg-blue-500/10">
              <MessageSquare className="text-blue-400 w-6 h-6" />
            </div>
            <h4 className="text-xl font-bold">Investigation</h4>
          </div>
          <p className="text-white/70">
            Gestion automatisée des investigations et des retours avec workflow intégré et communication inter-bancaire normalisée.
          </p>
        </div>
      </div>

      {/* Additional Benefits */}
      <div className="mt-24 text-center">
        <h3 className="text-3xl font-bold mb-8">
          Une Solution 
          <span className="text-blue-400"> Évolutive</span>
        </h3>
        <p className="text-xl text-white/70 max-w-3xl mx-auto">
          MXPAY s'adapte à vos besoins grâce à son architecture modulaire et ses capacités d'intégration avancées. Que vous soyez une banque régionale ou un acteur international, MXPAY évolue avec vous.
        </p>
      </div>
    </div>
  </div>
</section>
    </div>
  );
}