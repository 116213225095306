import React from 'react';
import LandingMXPAY from './pages/landingmxpay';

const App = () => {
  return (
    <div className="min-h-screen">
      <LandingMXPAY />
    </div>
  );
};

export default App;